<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <div>欢迎使用</div>
    </div>
    <div class="copyright-div">
      <div>备案信息</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main"
};
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 20px 10px 20px;
  }
  .copyright-div {
    height: 44px;
    width: 100%;
    padding: 15px 0;
    font-size: 16px;
    color: #787878;
    text-align: center;
    border-top: 1px solid #d7d7d7;
    margin-top: 15px;
  }
}
</style>
